import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#C60000" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            {/* <CFLink href="https://www.facebook.com/tontonsushi/photos/?ref=page_internal">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Mainichi Sushi (Old Ton Ton) Facebook"
              />
            </CFLink> */}
            {/* <CFLink href="https://www.yelp.ca/biz/chicko-chicken-coquitlam">
              <CFImage w="45px" pr="10px" src={yelp} alt="Mainichi Sushi (Old Ton Ton) Yelp" />
            </CFLink>
            <CFLink href="https://www.zomato.com/victoria-bc/chimac-korean-pub-and-fried-chicken-victoria-city-of-victoria">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Mainichi Sushi (Old Ton Ton) Zomato"
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/mainichi.van/">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Mainichi Sushi (Old Ton Ton) Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#C60000" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            {/* <CFLink href="https://www.facebook.com/tontonsushi/photos/?ref=page_internal">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Mainichi Sushi (Old Ton Ton) Facebook"
                hover
              />
            </CFLink> */}
            {/* <CFLink href="https://www.yelp.ca/biz/chicko-chicken-coquitlam">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="Mainichi Sushi (Old Ton Ton) Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/victoria-bc/chimac-korean-pub-and-fried-chicken-victoria-city-of-victoria">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="Mainichi Sushi (Old Ton Ton) Zomato"
                hover
              />
            </CFLink> */}
            <CFLink href="https://www.instagram.com/mainichi.van/">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Mainichi Sushi (Old Ton Ton) Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
